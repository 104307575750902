import './loadingModal.css';

const LoadingModal = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-modal">
        <div className="spinner-container">
        <div className="loading-spinner"></div>
        </div>
        <p>Analyzing Document...</p>
      </div>
    </div>
  );
};

export default LoadingModal;