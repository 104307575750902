
// Login.js
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useState, useContext, useEffect } from "react";

import {useLocation, useNavigate} from "react-router-dom";

import {useIsFirstRender} from "../../lib/usehooks/useIsFirstRender";

import {signInAuthUserWithEmailAndPassword} from "../../firebase/firebase.utils";

import { UserContext } from "../../contexts/user.context";
import './login.css'; // Import your login stylesheet here

const Login = () => {
    const [loginUsername, setLoginUsername] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [errorShow, setErrorShow] = useState(null);

    const {setCurrentUser} = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext)
    const isFirstRender = useIsFirstRender();

    async function Submit(e)  {
      e.preventDefault()
      
      try {
          const {user} = await signInAuthUserWithEmailAndPassword(loginUsername, loginPassword)
          if (!user.isEmailVerified){
            setErrorShow("Please verify your email before logging in");
          }
          setCurrentUser(user);
          navigate('/home');
          }
        catch (error) {
              var errorCode = error.code;
              var errorMessage = error.message;
              console.log(errorMessage + errorCode);
              setErrorShow("Invalid Username or Password");
          }
  }

  useEffect(() => {
      // If this is the first render of this component, then
      // redirect if we have a valid user and we do not have an existing route path
      if (isFirstRender && currentUser && (!location?.pathname || location?.pathname === '/')) {
          navigate('/home');
      }
  }, [currentUser, isFirstRender, location?.pathname, navigate]);
  return (
    <div className='errorcontainer'>
      {!errorShow ? <div> </div> : <div className="error">{errorShow}</div>}
      <div className="loginbody">
      
        <div className="logincontainer">
          <div className="loginheader">
            <h2>Login</h2>
          </div>
          <form className="loginform-group" onSubmit={(e) => Submit(e)}>
            <input type="text" placeholder="Username" onChange={(e) => setLoginUsername(e.target.value)} required autoFocus />
            <input type="password" placeholder="Password" onChange={(e) => setLoginPassword(e.target.value)} required />
            <button type="submit">Login</button>
          </form>
          <div className="loginfooter">
            <p>
              Don't have an account? <Link to="/register">Sign up</Link> or <Link to="/resetPassword">Reset</Link> your password
            </p>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Login;
