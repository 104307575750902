import React, { useState } from 'react';
import Navbar from '../navbar/navbar.jsx';
import LoadingModal from '../loadingModal/loadingModal';
import './home.css';

const apiUrl = process.env.REACT_APP_SOW_API;

const FileItem = ({ fileName, onRemove }) => {
  return (
    <div className="file-item">
      <span>{fileName}</span>
      <div className="buttons-container">
        <button onClick={() => onRemove(fileName)}>Delete</button>
      </div>
    </div>
  );
};

const Home = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docxBlob, setDocxBlob] = useState(null);
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFiles = (files) => {
    const newFiles = Array.from(files);
    setFileList([...fileList, ...newFiles]);
    setShowGenerateButton(true);
  };

  const removeFile = (fileName) => {
    const updatedFiles = fileList.filter((file) => file.name !== fileName);
    setFileList(updatedFiles);
    if (updatedFiles.length === 0) {
      setShowGenerateButton(false);
    }
  };

  const generateLeads = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('pdf', file);
    });

    setLoading(true);

    try {
      const response = await fetch(`https://${apiUrl}/upload`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const contentType = response.headers.get('content-type');

        if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
          // Handle DOCX response
          const blob = await response.blob();
          setDocxBlob(blob);
        } else {
          // Handle other responses if needed
          // For now, assume it's a PDF
          const blob = await response.blob();
          setDocxBlob(blob);
        }

        setLoading(false);
        setShowGenerateButton(false);
        setShowDownloadButton(true);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error generating leads:', error);
      setLoading(false);
    }
  };

  const downloadDocxFile = () => {
    if (docxBlob) {
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(docxBlob);
      downloadLink.href = url;
      downloadLink.download = 'GeneratedLeads.docx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <div>
      <Navbar />

      <div className="container">
        <p>Please upload PDFs to generate leads.</p>

        <div
          className="drag-drop-area"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <p>Drag & Drop files here or click to upload.</p>
          <input
            type="file"
            accept=".pdf"
            id="uploadInput"
            style={{ display: 'none' }}
            onChange={(e) => handleFiles(e.target.files)}
            multiple
          />
          <button type="button" onClick={() => document.getElementById('uploadInput').click()}>
            Upload
          </button>
        </div>

        <div className="file-list">
          {fileList.map((file) => (
            <FileItem key={file.name} fileName={file.name} onRemove={removeFile} />
          ))}
        </div>

        {loading && <LoadingModal />}

        {/* Generate Leads Button */}
        {showGenerateButton && (
          <div className="generate-leads-button">
            <button type="button" onClick={generateLeads}>
              Generate Leads
            </button>
          </div>
        )}

        {/* Download Leads Button */}
        {showDownloadButton && (
          <div className="download-pdf-button">
            <button type="button" onClick={downloadDocxFile}>
              Download Leads
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
