import { useContext } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import { signOutUser } from "../../firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";
import './navbar.css'

const Navbar = () => {
  const {setCurrentUser} = useContext(UserContext)
    const navigate = useNavigate();
    
    
    async function logOut() {
        signOutUser();
        setCurrentUser(null);
        navigate('/');
    }
    return (
      <div className="navbar">
        <p >AI Lead Generator</p>
        <NavLink onClick={logOut} to="/">Logout</NavLink>
      </div>
    );
  };
  
  export default Navbar;