/* eslint-disable react/no-unescaped-entities */
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useState, useContext, useEffect } from "react";
import {registerWithEmailAndPassword, sendUserValidationEmail, signOutUser} from "../../firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";
import { useNavigate, useLocation } from "react-router-dom";
import {useIsFirstRender} from "../../lib/usehooks/useIsFirstRender";
import './register.css';

const Register = () => {
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState("");
    const [errorShow, setErrorShow] = useState(null);
    const [messageShow, setMessageShow] = useState(null);
    //const {setCurrentUser} = useContext(UserContext)
    const navigate = useNavigate();
    const location = useLocation();
    const {currentUser, setCurrentUser} = useContext(UserContext)
    const isFirstRender = useIsFirstRender();

    
    const isPasswordConfirmed = (password,confimPassword) => {
      if(password && confimPassword && password === confimPassword) return true;
      return false;
    }

    async function register(e) {
        e.preventDefault();
        if(!isPasswordConfirmed(registerPassword, registerPasswordConfirm)){
          setErrorShow("Passwords Do Not Match")
          return;
        }
        try {
            const {user} = await registerWithEmailAndPassword(registerEmail, registerPassword, registerPasswordConfirm)
            //setCurrentUser(user);
            sendUserValidationEmail(user)
            setMessageShow("Account created please check your Email to verify before logging in")
            signOutUser();
	    setCurrentUser(null)
	    //navigate('/home');
            }
           catch (error){
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorMessage + errorCode);
                setErrorShow("There was an issue creating your account, contact service@it-spot.net");
            }
    }

    useEffect(() => {
        // If this is the first render of this component, then
        // redirect if we have a valid user and we do not have an existing route path
        if (isFirstRender && currentUser && (!location?.pathname || location?.pathname === '/')) {
            navigate('/home');
        }
    }, [currentUser, isFirstRender, location?.pathname, navigate]);
    return (
      <div className='errorcontainer'>
        {!errorShow ? <div> </div> : <div className="error">{errorShow}</div>}
        {!messageShow ? <div> </div> : <div className="success">{messageShow}</div>}
        <div className='registerbody'>
            <div className="registercontainer">
            <div className="registerheader">
              <h2>Register</h2>
            </div>
            <form className="registerform-group" onSubmit={(e) => register(e)}>
              <input type="text" placeholder="Email" onChange={(e) => setRegisterEmail(e.target.value)} required autoFocus />
              <input type="password" placeholder="Password" onChange={(e) => setRegisterPassword(e.target.value)} required />
              <input type="password" placeholder="Password Confirm" onChange={(e) => setRegisterPasswordConfirm(e.target.value)} required />
              <button type="submit">Register</button>
            </form>
            <div className="registerfooter">
              {/* Use Link to navigate to the Register component */}
              <p>Already have an account? <Link to="/">Login</Link> or <Link to="/resetPassword">Reset</Link> your password</p> 
            </div>
          </div>
        </div>
      </div>
    );
  };
  
export default Register;
